<template>
  <tr>
    <td width="3%" v-if="levelValue === 2 || levelValue === 3">&nbsp;</td>
    <td width="3%" v-if="levelValue === 3">&nbsp;</td>
    <td width="3%">{{prefix}}</td>
    <td :colspan="colspan">{{title}}</td>
    <td v-if="value !== null" width="15%" class="text-right" :class="styleClass">{{text}} EUR</td>
  </tr>
</template>
<script>

export default {
  name: 'BalanceSheetRow',
  props: {
    prefix: {},
    title: {},
    value: {},
    level: {},
    negative: {}
  },
  computed: {
    styleClass: function () {
      return this.negative && parseInt(this.value) !== 0 ? "red--text": "";
    },
    text: function () {
      return this.negative && parseInt(this.value) > 0 ? "-" + this.value : this.value;
    },
    levelValue: function () {
      return parseInt(this.level);
    },
    colspan: function () {
      if (this.levelValue === 3 && this.value === null) {
        return 2;
      }
      if (this.levelValue === 3 && this.value !== null) {
        return 1;
      }
      if (this.levelValue === 2 && this.value === null) {
        return 3;
      }
      if (this.levelValue === 2 && this.value !== null) {
        return 2;
      }
      if (this.value === null) {
        return 4;
      }
      return 3;
    }
  },
}
</script>
