<template>

  <v-container class="mt-10 pt-10">
    <v-row>
      <v-col>
        <v-card class="mt-10">
          <v-toolbar
              color="teal darken-4"
              dark
              flat
          >
            <v-toolbar-title>{{details.baseData.company}} {{details.baseData.companyType}}</v-toolbar-title>
            <v-spacer></v-spacer>

<!--            <v-btn icon>-->
<!--              <v-icon>mdi-printer</v-icon>-->
<!--            </v-btn>-->
            <v-btn icon title="Als PDF exportieren" @click="downloadPdf">
              <v-icon>mdi-file-pdf-outline</v-icon>
            </v-btn>
            <v-btn icon title="Als Exceldatei exportieren" @click="downloadExcel">
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
<!--            <v-btn icon>-->
<!--              <v-icon>mdi-dots-vertical</v-icon>-->
<!--            </v-btn>-->

            <template v-slot:extension>
              <v-tabs v-model="tab"  grow>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab key="basedata">
                  Stammdaten
                </v-tab>
                <v-tab key="insolvency">
                  Insolvenzdaten
                </v-tab>
                <v-tab key="indebtness">
                  Überschuldung
                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>
          <v-tabs-items v-model="tab">
            <v-tab-item key="basedata">
              <v-card flat>
                <v-card-text>
                  <v-card-subtitle class="mx-0 px-0"><h3>Stammdaten</h3></v-card-subtitle>
                  <v-container class="my-0 py-0">
                    <v-row>
                      <v-col cols="6" sm="2">Aktenzeichen</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.identifier}}</v-col>
                      <v-col cols="6" sm="2">Angelegt am</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.created}}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="2">Status</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.confirmed === null ? 'Unbestätigt' : 'Bestätigt'}}</v-col>
                      <v-col cols="6" sm="2">Bestätigt am</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.confirmed}}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="2">Geschäftsführer</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.ceo}}</v-col>
                      <v-col cols="6" sm="2">Letzte Aktualisierung</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.lastUpdate}}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="2">Straße</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.street}}</v-col>
                      <v-col cols="6" sm="2">PLZ / Ort</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.zipcode}} {{details.baseData.city}}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="2">Website</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.website}}</v-col>
                    </v-row>
                  </v-container>
                  <v-divider></v-divider>
                  <v-card-subtitle class="mx-0 px-0"><h3>Kontaktdaten</h3></v-card-subtitle>
                  <v-container class="my-0 py-0">
                    <v-row>
                      <v-col cols="6" sm="2">Telefon</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.phone}}</v-col>
                      <v-col cols="6" sm="2">E-Mail</v-col>
                      <v-col cols="6" sm="4"><a :href="emailLink">{{details.baseData.email}}</a></v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="2">Zeitfenster</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.preferredContactTime}}</v-col>
                      <v-col cols="6" sm="2">Bevorzugte Kontaktart</v-col>
                      <v-col cols="6" sm="4">{{details.baseData.preferredContactType}}</v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="insolvency">
              <v-card flat>
                <v-card-text>
                  <v-card-subtitle><h3>Angaben zum Stichtag heute ({{details.baseData.created}})</h3></v-card-subtitle>
                  <v-simple-table>
                    <template>
                      <tbody>
                      <CurrencyTableCell title="Kontostand" :value="details.insolvencyData.balance"  />
                      <CurrencyTableCell title="Barmittel / Liquiditätsreserven" :value="details.insolvencyData.cash" />
                      <CurrencyTableCell title="Bestehende Kreditlinie" :value="details.insolvencyData.creditLimit" />
                      <CurrencyTableCell title="Überfällige Verbindlichkeiten" :value="details.insolvencyData.currentDebt" negative="true" />
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <v-card-subtitle><h3>Angaben zum Stichtag in 3 Wochen</h3></v-card-subtitle>
                  <v-simple-table>
                    <template>
                      <tbody>
                      <CurrencyTableCell title="Finanzeinzahlungen" :value="details.insolvencyData.financialDeposits" />
                      <CurrencyTableCell title="Erfolgseinzahlungen" :value="details.insolvencyData.profitDeposits"  />
                      <CurrencyTableCell title="Finanzauszahlungen" :value="details.insolvencyData.financialPayments" negative="true" />
                      <CurrencyTableCell title="Erfolgsauszahlungen" :value="details.insolvencyData.profitPayments" negative="true" />
                      <CurrencyTableCell title="Überfällige Verbindlichkeiten" :value="details.insolvencyData.expectedDebt" negative="true" />
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <v-card-subtitle><h3>Berechnung</h3></v-card-subtitle>
                  <v-simple-table>
                    <template>
                      <tbody>
                      <CurrencyTableCell v-if="parseInt(details.insolvencyData.summary.currentCoverageGap) < 0" title="Deckungslücke (heute)" :value="details.insolvencyData.summary.currentCoverageGap" negative="true" />
                      <CurrencyTableCell title="Prognose Gesamtverschuldung" :value="details.insolvencyData.summary.totalDebt" negative="true" />
                      <CurrencyTableCell title="Prognose Gesamtliquidität" :value="details.insolvencyData.summary.totalLiquidity" />
                      <CurrencyTableCell v-if="details.insolvencyData.summary.expectedCoverageGap < 0" title="Deckungslücke (in 3 Wochen)" :value="details.insolvencyData.summary.expectedCoverageGap" negative="true" />
                      <tr>
                        <td>Ergebnis</td>
                        <td class="text-right">
                          <v-chip v-if="details.insolvencyData.summary.status === 'Red'" small color="red" dark>Rot</v-chip>
                          <v-chip v-if="details.insolvencyData.summary.status === 'Yellow'" small color="yellow">Gelb</v-chip>
                          <v-chip v-if="details.insolvencyData.summary.status === 'Lime'" small color="lime">Grüngelb</v-chip>
                          <v-chip v-if="details.insolvencyData.summary.status === 'Green'" small color="green">Grün</v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item key="indebtness">
              <v-card flat>
                <v-card-text>
                  <v-card-subtitle><h3>Aktiva</h3></v-card-subtitle>
                  <v-simple-table>
                    <template>
                      <tbody>
                        <BalanceSheetRow level="1" prefix="I." title="Ausstehende Einlagen" :value="details.indebtnessData.assets.outstandingDeposits" />
                        <BalanceSheetRow level="1" prefix="II." title="Anlagevermögen" :value="null" />
                        <BalanceSheetRow level="2" prefix="1." title="Grundstück und Gebäude, grundstücksgleiche Rechte" :value="details.indebtnessData.assets.realEstate" />
                        <BalanceSheetRow level="2" prefix="2." title="Maschinen, maschinelles Anlagevermögen" :value="details.indebtnessData.assets.machineryEquipment" />
                        <BalanceSheetRow level="2" prefix="3." title="Fuhrpark" :value="details.indebtnessData.assets.vehicles" />
                        <BalanceSheetRow level="2" prefix="4." title="Betriebs- und Geschäftsausstattung" :value="details.indebtnessData.assets.officeEquipment" />
                        <BalanceSheetRow level="2" prefix="5." title="Beteiligungen" :value="details.indebtnessData.assets.investments" />
                        <BalanceSheetRow level="1" prefix="III." title="Umlaufvermögen" :value="null" />
                        <BalanceSheetRow level="2" prefix="A." title="Vorratsvermögen" :value="null" />
                        <BalanceSheetRow level="3" prefix="1." title="Roh-, Hilfs- u. Betriebsstoffe" :value="details.indebtnessData.assets.materialsAndSupplies" />
                        <BalanceSheetRow level="3" prefix="2." title="Halbfertige Arbeiten" :value="details.indebtnessData.assets.workInProgress" />
                        <BalanceSheetRow level="2" prefix="B." title="Andere Gegenstände des Umlaufvermögens" :value="null" />
                        <BalanceSheetRow level="3" prefix="1." title="Forderungen aus Lieferungen u. Leistungen" :value="details.indebtnessData.assets.tradeAccountClaims" />
                        <BalanceSheetRow level="3" prefix="2." title="Sonstige Forderungen" :value="details.indebtnessData.assets.otherClaims" />
                        <BalanceSheetRow level="3" prefix="3." title="Kassenbestand" :value="details.indebtnessData.assets.cash" />
                        <BalanceSheetRow level="3" prefix="4." title="Guthaben bei Kreditinstituten" :value="details.indebtnessData.assets.balances" />
                        <BalanceSheetRow level="3" prefix="5." title="Schadensersatz- u. Erstattungsansprüche" :value="details.indebtnessData.assets.reimbursements" />
                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="4">Summe</td>
                        <td class="text-right">{{ details.indebtnessData.assets.total }} EUR</td>
                      </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <v-card-subtitle><h3>Passiva</h3></v-card-subtitle>
                  <v-simple-table>
                    <template>
                      <tbody>
                      <BalanceSheetRow level="1" prefix="I." title="Rückstellungen" :value="details.indebtnessData.liabilities.accruals" />
                      <BalanceSheetRow level="1" prefix="II." title="Verbindlichkeiten" :value="null" />
                      <BalanceSheetRow level="2" prefix="1." title="Verbindlichkeiten aus Lieferungen u. Leistungen" :value="details.indebtnessData.liabilities.tradeAccountLiabilities" />
                      <BalanceSheetRow level="2" prefix="2." title="Verbindlichkeiten gegenüber Kreditinstituten" :value="details.indebtnessData.liabilities.bankLiabilities" />
                      <BalanceSheetRow level="2" prefix="3." title="Verbindlichkeiten gegenüber verbundenen Unternehmen" :value="details.indebtnessData.liabilities.affiliateLiabilities" />
                      <BalanceSheetRow level="2" prefix="4." title="Steuern und Abgaben" :value="details.indebtnessData.liabilities.taxesAndDuties" />
                      <BalanceSheetRow level="2" prefix="5." title="Löhne und Gehälter" :value="details.indebtnessData.liabilities.wagesAndSalaries" />
                      <BalanceSheetRow level="2" prefix="6." title="Sozialversicherungsbeiträge" :value="details.indebtnessData.liabilities.socialSecurityContributions" />
                      <BalanceSheetRow level="2" prefix="7." title="Sonstige Verbindlichkeiten" :value="details.indebtnessData.liabilities.otherLiabilities" />
                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="4">Summe</td>
                        <td class="text-right">{{ details.indebtnessData.liabilities.total }} EUR</td>
                      </tr>
                      </tfoot>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text>
                  <v-card-subtitle><h3>Berechnung</h3></v-card-subtitle>
                  <v-simple-table>
                    <template>
                      <tbody>
                      <CurrencyTableCell title="Vermögen bei Liquidation" :value="details.indebtnessData.summary.totalAssets" />
                      <CurrencyTableCell title="Verbindlichkeiten" :value="details.indebtnessData.summary.totalLiabilities" negative="true" />
                      <CurrencyTableCell title="Überschuldung" :value="details.indebtnessData.summary.indebtness" negative="true" />
                      <tr>
                        <td>Ergebnis</td>
                        <td class="text-right">
                          <v-chip v-if="details.indebtnessData.summary.status === 'Red'" small color="red" dark>Rot</v-chip>
                          <v-chip v-if="details.indebtnessData.summary.status === 'Yellow'" small color="yellow">Gelb</v-chip>
                          <v-chip v-if="details.indebtnessData.summary.status === 'Lime'" small color="lime">Grüngelb</v-chip>
                          <v-chip v-if="details.indebtnessData.summary.status === 'Green'" small color="green">Grün</v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-link :to="{ name: 'Beratungsanfragen' }">Zurück zur Übersicht</router-link>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
  tfoot tr td {
      font-weight: bold;
  }
</style>
<script>
import CurrencyTableCell from "@/components/CurrencyTableCell";
import BalanceSheetRow from "@/components/BalanceSheetRow";
export default {
  name: 'ConsultingDetailView',
  components: {CurrencyTableCell, BalanceSheetRow},
  props: {},
  data: () => ({
    tab: 'basedata',
    showError: false,
    error: null,
    loading: false,
    details: {
      baseData: {
        company: '',
        companyType: ''
      },
      insolvencyData: {
        currentDebt: 0,
        balance: 0,
        cash: 0,
        creditLimit: 0,
        expectedDebt: 0,
        financialDeposits: 0,
        financialPayments: 0,
        profitDeposits: 0,
        profitPayments: 0,
        summary: {
          totalLiquidity: 0,
          totalDebt: 0,
          currentCoverageGap: 0,
          expectedCoverageGap: 0,
          debtPercentage: 0,
          status: "Unknown"
        }
      },
      indebtnessData: {
        assets: {
          outstandingDeposits: 0,
          realEstate: 0,
          machineryEquipment: 0,
          vehicles: 0,
          officeEquipment: 0,
          investments: 0,
          materialsAndSupplies: 0,
          workInProgress: 0,
          tradeAccountClaims: 0,
          otherClaims: 0,
          cash: 0,
          balances: 0,
          reimbursements: 0,
          total: 0
        },
        liabilities: {
          accruals: 0,
          tradeAccountLiabilities: 0,
          bankLiabilities: 0,
          affiliateLiabilities: 0,
          taxesAndDuties: 0,
          wagesAndSalaries: 0,
          socialSecurityContributions: 0,
          otherLiabilities: 0,
          total: 0
        },
        summary: {
          totalAssets: 0,
          totalLiabilities: 0,
          indebtness: 0,
          status: "Unknown"
        }
      },
    }
  }),
  computed: {
    phoneLink() {
      return 'tel:' + this.details.phone;
    },
    emailLink() {
      return 'mailto:' + this.details.email;
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    console.log("ConsultingDetailView::created");
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData() {
      console.log("ConsultingDetailView::fetchData");
      this.error = this.post = null
      this.loading = true
      let self = this;
      this.$http.get(`${process.env.VUE_APP_ENDPOINT}/admin/consulting-requests/details/${this.$route.params.id}`)
          .then(function (response) {
            self.details = response.data;
          })
          .catch(function (error) {
            if (error.response.status === 500) {
              self.error = "Da ist was schief gelaufen...";
            } else {
              self.error = error.response;
            }
            self.showError = true
          })
          .finally(() => this.loading = false)
      ;
    },
    downloadExcel() {
      window.open(`${process.env.VUE_APP_ENDPOINT}/admin/consulting-requests/details/${this.$route.params.id}/export.xlsx`)
    },
    downloadPdf() {
      window.open(`${process.env.VUE_APP_ENDPOINT}/admin/consulting-requests/details/${this.$route.params.id}/export.pdf`)
    },

  }
}
</script>
