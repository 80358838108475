<template>
  <tr>
    <td>{{title}}</td>
    <td class="text-right" :class="styleClass">{{text}} EUR</td>
  </tr>
</template>
<script>

export default {
  name: 'CurrencyTableCell',
  props: {
    title: {},
    value: {},
    negative: {}
  },
  computed: {
    styleClass: function () {
      return this.negative && parseInt(this.value) !== 0 ? "red--text": "";
    },
    text: function () {
      return this.negative && parseInt(this.value) > 0 ? "-" + this.value : this.value;
    }
  },
}
</script>
